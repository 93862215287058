import { render, staticRenderFns } from "./LoginOr.vue?vue&type=template&id=00642f19&scoped=true"
import script from "./LoginOr.vue?vue&type=script&lang=js"
export * from "./LoginOr.vue?vue&type=script&lang=js"
import style0 from "./LoginOr.vue?vue&type=style&index=0&id=00642f19&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00642f19",
  null
  
)

export default component.exports